<template>
  <div class="tabs" data-t="st-tabs" :class="[props.type, { grow }]">
    <label
      v-for="(tab, i) in expandedTabs"
      :key="tab.id"
      class="tabs-item"
      :class="tab.classes"
      :tabindex="i + 1"
      :data-t="`tab-${tab.id}-i8wh`"
    >
      <StIcon
        v-if="tab.icon && !isIconsDisabled"
        :size="iconSize"
        :name="tab.icon"
      />
      <input
        v-model="selectedTabId"
        type="radio"
        :value="tab.id"
        :name="tab.label"
        :disabled="tab.disabled"
      />
      <span v-if="tab.label">{{ tab.label }}</span>
      <StCounter
        v-if="tab.counter"
        :label="tab.counter"
        :live="false"
        :bg-color="
          tab.classes.selected
            ? counterStyle.selected.bgColor
            : counterStyle.default.bgColor
        "
        :color="
          tab.classes.selected
            ? counterStyle.selected.color
            : counterStyle.default.color
        "
        :size="type === 'mobile-m' ? 'xs' : 's'"
        data-t="tab-counter-poj1"
      />
      <slot :name="`postfix-${tab.id}`"></slot>
    </label>
  </div>
</template>

<script setup lang="ts">
import type { Tab } from './types'

interface Props {
  tabs: Tab[]
  modelValue: number | string
  type?:
    | 'default'
    | 'dark'
    | 'separate'
    | 'mobile-m'
    | 'mobile-l'
    | 'mobile-s'
    | 'desktop-s'
  grow?: boolean
  isIconsDisabled?: boolean
  counter?: {
    bgColor?: 'white' | 'orange' | 'transparent' | 'black' | 'ghost'
    selectedColor?: 'white' | 'orange' | 'transparent' | 'black' | 'ghost'
    color?: 'orange' | 'white'
  }
  tabMinWidth?: string
}
const props = withDefaults(defineProps<Props>(), {
  type: 'default',
  minWidth: 'auto',
  isIconsDisabled: false,
  counter: undefined,
  tabMinWidth: '',
})
const emit =
  defineEmits<
    (e: 'update:modelValue', value: typeof props.modelValue) => void
  >()

const selectedTabId = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})

const expandedTabs = computed(() =>
  props.tabs.map((tab) => ({
    ...tab,
    classes: {
      selected: tab.id === selectedTabId.value,
      disabled: tab.disabled,
      grow: props.grow,
      separate: props.type === 'separate',
      icon: !tab.label && tab.icon,
    },
  })),
)

const counterStyle = computed(() => ({
  default: {
    bgColor: props.counter?.bgColor || 'ghost',
    color: props.counter?.color || 'white',
  },
  selected: {
    bgColor: props.counter?.selectedColor || 'white',
    color: props.counter?.color || 'white',
  },
}))

const iconSize = computed(() =>
  ['mobile-s', 'mobile-m', 'mobile-l'].includes(props.type) ? 20 : 16,
)
</script>

<style scoped>
.tabs {
  user-select: none;
  display: inline-flex;
  /* stylelint-disable-next-line */
  gap: 1px;
  align-items: flex-start;

  &.grow {
    width: 100%;
  }
}

.tabs-item {
  cursor: pointer;

  position: relative;

  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: center;

  min-width: v-bind(tabMinWidth);
  padding: var(--spacing-100) var(--spacing-150);

  font: var(--desktop-text-sm-semibold);
  color: var(--text-secondary);
  white-space: nowrap;

  border-radius: var(--border-radius-100);

  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
  }
}

/* stylelint-disable no-descending-specificity */
.tabs.default,
.tabs.dark {
  padding: var(--spacing-025);
  background: var(--background-primary);
  border-radius: var(--border-radius-100);

  .tabs-item {
    &:nth-child(n + 1) ~ .tabs-item {
      &:not(:last-child)::after {
        content: '';

        position: absolute;
        right: -1px;

        width: 1px;
        height: 16px;

        background-color: var(--border-secondary);
      }
    }
  }
}

.tabs.dark {
  background: var(--background-base);
}

.tabs.separate,
.tabs.default,
.tabs.dark {
  .tabs-item {
    &:active {
      color: var(--text-primary);
      background: var(--button-gray-active);
    }

    &:hover,
    &:focus {
      color: var(--text-primary);
      background: var(--button-gray-hover);
    }

    &.selected {
      color: var(--text-primary);
      background: var(--button-gray-default);
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
    }

    &.disabled {
      pointer-events: none;
      color: var(--text-tertiary);
      opacity: 0.4;
    }

    &.grow {
      flex-basis: 0;
      flex-grow: 1;
      min-width: 0;
    }
  }
}

.tabs.separate {
  gap: var(--spacing-100);
  background: transparent;

  .tabs-item {
    padding: var(--spacing-100) var(--spacing-150);
    background-color: var(--background-primary);
  }
}

.tabs.mobile-m {
  background: transparent;

  .tabs {
    padding: var(--spacing-025);
  }

  .tabs-item {
    padding: var(--spacing-100) var(--spacing-125);
    font: var(--mobile-text-medium);
    border-radius: var(--border-radius-100);

    &.selected {
      color: var(--text-primary);
      background: var(--button-ghost-default);
    }

    &.disabled {
      color: var(--text-secondary);
      opacity: 0.4;
    }

    &.icon {
      padding: var(--spacing-075) var(--spacing-125);
    }
  }
}

.tabs.mobile-l {
  .tabs-item {
    padding: var(--spacing-050) var(--spacing-100);
    font: var(--mobile-title-2-semibold);
    color: var(--text-secondary);
    background: transparent;

    &.selected {
      color: var(--text-primary);
    }

    &.disabled {
      color: var(--text-secondary);
      opacity: 0.4;
    }
  }
}

.tabs.mobile-s {
  .tabs-item {
    gap: var(--spacing-075);

    padding: var(--spacing-050) var(--spacing-100);

    font: var(--mobile-headline-semibold);
    color: var(--text-secondary);

    background: transparent;

    &.selected {
      color: var(--text-primary);
    }

    &.disabled {
      color: var(--text-secondary);
      opacity: 0.4;
    }
  }
}

.tabs.desktop-s {
  .tabs-item {
    gap: var(--spacing-075);

    padding: var(--spacing-050) var(--spacing-100);

    font: var(--desktop-text-md-semibold);
    color: var(--text-secondary);

    background: transparent;

    &.selected {
      color: var(--text-primary);
    }

    &.disabled {
      color: var(--text-secondary);
      opacity: 0.4;
    }
  }
}
/* stylelint-enable no-descending-specificity */
</style>
